@import "src/style/mixins";
@import "src/style/variables";

.historyFiles {
  margin-bottom: 5rem;
  @include small-mobile {
    margin-bottom: 1.6rem;
  }

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 160%;
    color: $black;
    padding-top: 1.6rem;
    margin-bottom: 1.6rem;

    @include small-mobile {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin-bottom: 3.4rem;

    @include small-mobile {
      gap: 1.6rem;
      margin-bottom: 4rem;
    }
  }

  .historyFiles__load {
    position: relative;
    cursor: pointer;
    width: fit-content;
    display: flex;
    gap: 1.2rem;
    align-items: center;

    @include small-mobile {
      gap: 0.7rem;
      width: 100%;
    }

    input {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      @include hover {
        &:hover {
          transition: 0.3s;
          background: $m-blueL;
          color: $white;
        }
      }
    }
  }

  .btn-load {
    position: relative;
    padding: 1.6rem 3.2rem;

    @include small-mobile {
      font-size: 1.4rem;
      max-width: 18rem;
      padding: 1.2rem 3.2rem;
    }

    label {
      display: block;
    }
  }

  &__load-description {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;


    span {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 160%;
      color: $black;

      @include small-mobile {
        font-size: 1rem;
      }
    }

    span:last-child {
      font-weight: 400;
    }
  }
}

.downloadFile {
  display: flex;
  gap: 1rem;
}

.downloadBtn {
  flex-shrink: 0;
  padding: 0.6rem 0.8rem;
  gap: 0.4rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 100%;
  background: #F8F8F8;
  box-shadow: none;

  @include hover {
    &:hover {
      svg {
        transition: 0.3s;
        fill: $m-blueL;
        stroke: $white;
      }
    }
  }

  svg {
    fill: #F8F8F8;
    stroke: #0062AA;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.deleteBtn {
  flex-shrink: 0;
  padding: 0.6rem 0.8rem;
  gap: 0.4rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 100%;
  background: $m-red;
  box-shadow: none;
  color: $white;

  @include hover {
    &:hover {
      background: #952B2BFF;
    }
  }
}

.historyFilesElem {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    display: flex;
    gap: 1.2rem;
    align-items: center;

    @include large-mobile {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.6rem
    }

    &-input {
      max-width: 72.6rem;
      width: 100%;
    }

    input {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 140%;
      color: $black;
      background: #f6f6f6;
      padding: 2rem 2.4rem;
      border: none;
      width: 100%;
      border-radius: 0.4rem;

      @include small-mobile {
        font-size: 1.2rem;
        padding: 1.6rem;
      }
    }
  }

  &__saveTitle {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 140%;
    color: $m-blue;
    transition: 0.3s;
    cursor: pointer;

    @include small-mobile {
      font-weight: 700;
      font-size: 1.1rem;
      line-height: 100%;
    }

    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blueL;
      }
    }
  }
}


