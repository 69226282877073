@import "src/style/mixins";
@import "src/style/variables";


.select-full {
  .react-select-container {
    .react-select__control {
      padding: 2.1rem 2.4rem 2rem ;

      @include small-mobile {
        padding: 1.6rem 1.1rem 1.7rem 1.3rem;
      }
    }

    .react-select__menu {
      padding-top: 6.2rem;
    }
  }
}

.form-select {

}


.react-select-container {
  .react-select__control {
    background: #F6F6F6;
    border-radius: 0.4rem;
    border: 1px solid #F1F1F1;
    padding: 1.5rem 2.4rem;
    transition: 0.3s;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    z-index: 3;

    @include small-mobile {
      padding: 1.4rem 1.1rem 1.6rem 1.3rem;
    }

    @include hover {
      &:hover {
        background: $white;
        border: 1px solid $m-blue;
        transition: 0.3s;

        .react-select__placeholder {
          color: $m-blackL;
        }

        .react-select__indicator {
          transition: 0.3s;

          svg {
            transition: 0.3s;
            fill: $m-blackL;
          }
        }
      }
    }
  }

  .react-select__control--menu-is-open {
    background: $white;
    border-color: transparent !important;
    z-index: 10;

    @include hover {
      &:hover {
        border-color: transparent;
      }
    }

    .react-select__placeholder {
      color: $m-blackL;
    }

    .react-select__indicator {
      transition: 0.3s;

      svg {
        transition: 0.3s;
        fill: $m-blackL;
      }
    }
  }

  .react-select__control--menu-is-open ~ div {
    z-index: 9;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__indicators {

  }

  .react-select__single-value {
    color: $m-blackL;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
    background: $white;
    transition: 0.3s;
  }

  .react-select__indicator {
    margin: 0;
    padding: 0;
    width: 1.8rem;
    height: 1.8rem;

    svg {
      transition: 0.3s;
      width: 100%;
      height: 100%;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    color: #6D6D6D;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin: 0;
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 700;
    color: $m-blackL;
  }


  .react-select__menu {
    margin: 0;
    animation: dropdown 0.3s ease-in-out forwards;
    transform-origin: top center;
    border-radius: 0;
    padding-top: 5rem;
    top: 0;
    padding-bottom: 1rem;
    padding-right: 0.8rem;
    box-shadow: 0 14px 34px 0 rgba(0, 0, 0, 0.08);
    z-index: 2;
  }

  @keyframes dropdown {
    from {
      opacity: 0;
      transform: scaleY(0);
    }
    to {
      opacity: 1;
      transform: scaleY(1);
    }
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__group {
/*    padding: 0;*/
    margin-top: -0.3rem;
  }

  .react-select__group > div:last-child {
    max-height: 10.4rem;
    /*    overflow-y: auto;*/
    margin-right: 0.8rem;

  }

  .css-1n6sfyn-MenuList {
    transition: 0.5s;
/*    overflow-y: scroll;*/
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0.4rem; /* ширина полосы прокрутки */
      height: 1rem;
      background: #F3F3F3;
      border-radius: 9.9rem;
      transition: 0.5s;

    }

    &::-webkit-scrollbar-thumb {
      background: $m-blue; /* цвет полосы прокрутки */
      width: 0.4rem;
      border-radius: 9.9rem;
      height: 1rem;
      transition: 0.5s;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* цвет полосы прокрутки при наведении на нее */
    }
  }

  .react-select__option {
    color: $m-blackL;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    padding: 0 2.5rem;
    background: $white;
    transition: 0.3s;
    cursor: pointer;
    margin-bottom: 0.8rem;

    &:last-child {
      margin-bottom: 0;
    }


    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
      }
    }

  }

  .react-select__group-heading {
    color: #6D6D6D;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    text-transform: initial;
    padding: 0 2.5rem;
    margin: 0 0 0.8rem 0;
  }

  .react-select__input {
    /*    color: $m-blackL !important;*/
    /*    font-size: 1.4rem !important;*/
    /*    font-weight: 700 !important;*/
  }

}

.react-select-container-choosed {
  .react-select__control {
    background: $white;
    border-color: $m-blue;
  }

  .react-select__indicator {
    svg {
      fill: $m-blackL;
    }
  }
}

.grid-item-1,
.grid-item-2,
.grid-item-3 {
  grid-row: 1;
  grid-column: span 4;
}

/* Стили для элементов в нижней строке */
.grid-item-4,
.grid-item-5,
.grid-item-6,
.grid-item-7 {
  grid-row: 2;
  grid-column: span 3; /* Каждый элемент занимает одну колонку */

  @include small-tablet {
    grid-column: span 6; /* Каждый элемент занимает одну колонку */
  }
}

.grid-item-6,
.grid-item-7 {
  @include small-tablet {
    grid-row: 3;
  }
}


.react-select-container-error{
  border-color: $m-red;
  color: $m-red;

  .react-select__placeholder {
    color: $m-red;
  }
}




