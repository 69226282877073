@import "src/style/mixins";
@import "src/style/variables";

.confirm {
  display: flex;
  height: 100%;
  @include small-app {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .confirm-left {
    width: 50%;

    @include large-mobile {
      display: none;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .confirm-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include large-mobile {
      width: 100%;
    }
  }

  .confirm-right-body {
    max-width: 36rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.8rem;

    @include small-app {
      gap: 2.4rem;
    }
  }

  .btnBlock {
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4rem;

    @include small-app {
      margin-top: 3.2rem;
      gap: 3.4rem;
    }
  }
  .forgotBtn {
    color: #6B6B6B;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s;
    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
      }
    }
  }
}

.titleLogin {
  @include large-mobile {
    svg {
      max-width: 20.16rem;
      max-height: 5.4rem;
      width: 100%;
      height: 100%;
    }
  }
}

.error {
  padding-top: 0.5rem;
  //position: absolute;
  left: 0;
  font-size: 12px;
  line-height: 12px;
  color: $m-red;
}