@import "src/style/mixins";
@import "src/style/variables";




.btnBlock {
  margin-top: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;

  @include small-mobile {
    margin-top: 3.2rem;
    gap: 3.4rem;
  }
}
.forgotBtn {
  color: #6B6B6B;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: 0.3s;
  @include hover {
    &:hover {
      transition: 0.3s;
      color: $m-blue;
    }
  }
}