@import "src/style/mixins";
@import "src/style/variables";

.title {
  color: #CBCBCB;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 2.4rem;
}

.final-order-titleBlock {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 3.6rem;
}

.tour-date {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  color: $m-blackL;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 140%;

  @include small-mobile {
    font-size: 1.4rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: $m-blue;
  }
}

.tour-title {
  color: $black;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;

  @include small-mobile {
    font-size: 1.8rem;
  }
}

.final-order-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10rem 10.3rem;
  margin-bottom: 6rem;

  @include tablet-vert {
    grid-template-columns: repeat(2, 1fr);
  }

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 4.1rem;
  }
}


.order-elem-title {
  color: $m-blue;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 1.6rem;

  @include small-mobile {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}

.order-elem-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  @include small-mobile {
    margin-bottom: 1.2rem;
  }
}

.order-elem-item {
  display: flex;
  align-items: center;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #F0F0F0;
  justify-content: space-between;

  @include small-mobile {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:last-child {
    border-bottom: none;
  }

  span:first-child {
    color: #838383;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 100%;

    @include small-mobile {
      font-size: 1.1rem;
    }
  }

  span:last-child {
    color: $m-blackL;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 140%;

    @include small-mobile {
      font-size: 1.2rem;
    }
  }
}

.additional-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 88.1rem;
  gap: 1.9rem;
  margin-bottom: 2.4rem;

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
    margin-bottom: 0.4rem;
  }
}


.title {
  color: #CBCBCB;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 2.4rem;

  @include medium-mobile {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
}

.paymentBlock-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.9rem;

  @include small-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
  }
}

.paymentBlock {
  margin-bottom: 4.8rem;

  @include medium-mobile{
    margin-bottom: 4rem;
  }
}

.paymentBlock-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 1.2rem;

  @include medium-mobile {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.input-payment {
  input {
    padding-left: 2.4rem !important;

    @include medium-mobile{
      padding-left: 1.4rem !important;
    }
  }
}

.successBtn {
  background: $m-green;
  color: $white;
  svg {
    fill: $m-green;
    stroke: $white;
  }

  @include small-mobile {
   width: 100%;
  }

  @include hover {
    &:hover {
      background: $m-blueL;
      svg {
        fill: $m-blueL;
      }
    }
  }
}

.place-wrapper {
  display: flex;
  gap: 3.2rem;
  align-items: center;
  width: fit-content;

  @include small-mobile {
    gap: 2.5rem;
  }
  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.place-wrapper:global(.place-wrapper--hidden) {
  display: none;
}


.place {
  position: relative;
  width: 3.4rem;

  @include medium-tablet {
    width: 4rem;
  }

  @include large-mobile {
    width: 5rem;
  }

  @include small-mobile {
    width: 3.4rem;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}
  .error {
    padding-top: 0.5rem;
    //position: absolute;
    left: 0;
    font-size: 1.4rem;
    line-height: 140%;
    color: $m-red;
    margin-bottom: 1rem;
  }


.firstBlock {
  position: relative;
}

.message {
  width: 100%;
  top: 100%;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: $m-blue;
}

