@import "src/style/mixins";
@import "src/style/variables";

.datepicker {
  display: flex;
  position: relative;
  height: fit-content;
  min-width: 25rem;

  .btn-datepicker {
    padding: 0.6rem 0.8rem;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 100%;
    justify-content: center;

    @include hover {
      &:hover {
        transition: 0.3s;
        background: $m-blue;
        color: $white;
      }
    }
  }

  .leftBlock {
    display: flex;
    position: absolute;
    flex-direction: column;
    max-width: 13.9rem;
    width: 100%;
    top: 100%;
    padding: 1.6rem 0.8rem;
    z-index: 2;
    background: #F9F9F9;
    border-radius: 0.4rem 0 0 0.4rem;
    gap: 0.8rem;
    visibility: hidden;
    opacity: 0;
  }

  input {
    background: #F6F6F6;
    padding: 1.5rem 2.4rem 1.5rem;
    -webkit-appearance: none;
    border-radius: 0.4rem;
    border: 1px solid #F1F1F1;
    width: 100%;
    color: $m-blackL;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    transition: .3s;
    position: relative;
    cursor: pointer;
    box-shadow: none;

    @include small-app {
      padding: 1.6rem;
    }

    &:focus {
      border-color: $m-blackL;
      background: $white;
    }

    &_error {
      position: relative;
      border-color: $m-red;
      background: $white;
      color: $m-red;
    }

    &_error::placeholder {
      color: $m-red !important;
    }

    &_outline {
      background: $white;
      border: 1px solid #545454;
    }

    @include hover {
      &:hover {
        background: $white;
        border: 1px solid $m-blue;
        transition: 0.3s;
      }
    }
  }

  input::placeholder {
    color: #6D6D6D;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .svg-fill {
    svg {
      fill: $m-blue;
    }
  }
}

.input-fill {
  transition: 0s;
  background: $white !important;
  border: 1px solid $m-blue !important;
  font-weight: 700 !important;
}

.litepicker {
  left: 7rem !important;
  top: 100% !important;

  @include medium-mobile {
    right: 0;
    left: auto !important;
  }

  .container__main {
    .month-item-name {
      color: #000;
      text-align: center;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }

    .month-item-year {
      color: #000;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    .month-item-weekdays-row {
      display: grid;
      grid-template-columns: repeat(7, 1fr);

      div {
        color: #828282;
        text-align: center;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        display: flex;
        justify-content: center;
        width: 3.6rem;
        @include small-mobile {
          width: 2.8rem;
        }
      }
    }

    .container__days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 0;
      padding: 0;
      margin: 0;

      >div {
        @include small-mobile {
          width: 2.8rem;
        }
      }
    }

    .day-item {
      width: 100%;
      height: 3.6rem;
      color: $m-blackL;
      text-align: center;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 !important;

      @include small-mobile {
        width: 2.8rem;
      }
    }

    .day-item.is-start-date, .day-item.is-end-date {
      background: $m-blue;
    }

    .day-item.is-in-range {
      background: rgba(0, 189, 235, 0.08);
    }

    .container__months {
      width: 100%;
    }

    .month-item {
      max-width: 26.8rem;

      @include small-mobile {
        width: 20rem;
      }
    }

    .button-next-month, .button-previous-month {
      transition: 0.7s;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
        transition: 0.3s;
      }
    }
  }
}

.wrapInput {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @include hover {
    &:hover {
      .placeholder-block {
        background: $white;
        border: 1px solid $m-blue;
        transition: 0.3s;

        span {
          color: $m-blackL;
          transition: 0.3s;
        }
      }

      svg {
        fill: $m-blue;
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2.4rem;
    width: 2rem;
    height: 2rem;
    pointer-events: none;
    fill: #6D6D6D;
    z-index: 1;
  }

/*  input {
    padding: 1.5rem 2.4rem 1.5rem 5.2rem;
  }*/
  input {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 5.2rem;
  }


}







