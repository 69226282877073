@import "src/style/mixins";
@import "src/style/variables";


.subTitle {
  color: $m-blue;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.4rem;

  @include small-mobile {
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
  }
}

.title {
  color: $black;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2.4rem;
  @include small-mobile {
    font-size: 1.8rem;
    line-height: 160%;
    margin-bottom: 1.5rem;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  max-width: 88.1rem;
  gap: 1.9rem;
  margin-bottom: 5rem;
  @include small-mobile {
    grid-template-columns: repeat(1,1fr);
    gap: 0.4rem;
    margin-bottom: 4rem;
  }
}

.step1-select {
  .react-select__control {
    padding: 2rem 2.4rem;
  }
}