@import "src/style/mixins";
@import "src/style/variables";

.header {
  background: #FBFBFB;

  @include small-tablet {
    display: none;
  }

  .header-inner {
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    @include tablet-vert {
      justify-content: space-between;
    }
  }

  .logo {
    width: 14.3rem;
    height: 3.831rem;
    margin-right: 7rem;

    @include tablet-vert {
      margin-left: 0;
      margin-right: 1rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tabs {
    display: flex;
    gap: 0.8rem;
    a {
      @include tablet-vert {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
    }
  }

  .name {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    @include tablet-vert {
      margin-left: 1rem;
    }

    span {
      font-size: 1.1rem;
      font-style: normal;
      line-height: 140%;
    }

    span:first-child {
      color: #858585;
      font-weight: 500;
    }

    span:last-child {
      font-size: 1.2rem;
      color: $black;
      font-weight: 700;
    }
  }

  .exit {
    gap: 0.8rem;
    align-items: center;
    margin-left: 3.3rem;
    background: transparent;
    color: #232323;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    transition: 0.3s;
    box-shadow: none;
    padding: 0;

    @include small-desktop-min {
      margin-left: 3rem;
    }

    @include tablet-vert {
      margin-left: 1rem;
    }

    @include hover {
      &:hover {
        background: transparent;
        color: $m-blue;
        box-shadow: none;
      }
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
      stroke: $black;
      fill: none;
      transition: 0.3s;
    }


    @include hover {
      &:hover {
        span {
          transition: 0.3s;
          color: $m-blue;
        }
        svg {
          stroke: $m-blue;
          transition: 0.3s;
        }
      }
    }
  }

  .notification {
    position: relative;
    background: none;
    box-shadow: none;
    padding: 0;
    margin-left: 5.2rem;
    transition: 0.3s;

    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
        svg {
          stroke: $m-blue;
          transition: 0.3s;
        }
      }
    }

    @include tablet-vert {
      margin-left: 1rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
      stroke: $black;
      fill: none;
      transition: 0.3s;
    }

    span {
      position: absolute;
      right: -1rem;
      top: -1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      background: $m-blueL;
      border-radius: 50%;
      font-weight: 500;
      font-size: 1rem;
      line-height: 140%;
      color: $white;
    }
  }
}

