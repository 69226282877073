@import "src/style/mixins";
@import "src/style/variables";



.btn {
  width: fit-content;
  background: $white;
  font-size: 1.4rem;
  color: $black;
  cursor: pointer;
  transition: 0.3s;
  padding: 1.2rem 2.4rem 1.2rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  grid-gap: 1.2rem;
  height: fit-content;
  border-radius: 0.4rem;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.04);

  &:disabled {
    pointer-events: none;
  }

  svg path {
    fill: inherit;
    stroke: inherit;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: $m-blue;
    transition: 0.3s;
  }

  @include hover {
    &:hover {
      transition: 0.3s;
      background: $m-blueL;
      color: $white;

      svg {
        fill: $white;
        transition: 0.3s;
      }
    }
  }

  &.active {
    background: $m-blue;
    color: $white;
    transition: 0.3s;

    svg {
      fill: $white;
      transition: 0.3s;
    }
  }
}





