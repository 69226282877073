@import "src/style/mixins";
@import "src/style/variables";

.touristForm {
  margin-bottom: 4.8rem;

  @include small-mobile {
    margin-bottom: 4.1rem;
  }

  .touristForm-title {
    display: flex;
    align-items: center;
    padding: 1.6rem 2.4rem;
    border-radius: 0.4rem;
    border: 1px solid $m-blue;
    @include small-mobile {
      padding: 1.5rem 1.4rem 1.4rem 1.6rem;
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.8rem;
      @include small-mobile {
        width: 1.8rem;
        height: 2rem;
        margin-right: 1rem;
      }
    }

    span {
      color: #0062AA;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;

      @include small-mobile {
        font-size: 1.4rem;
      }
    }

    .wrap-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border: 1px solid $m-blue;
      border-radius: 50%;
      margin-left: auto;

      img {
        width: fit-content;
      }
    }
  }
}

.touristForm {
  .touristForm-title {
    margin-bottom: 2rem;

    @include small-mobile {
      margin-bottom: 1.9rem;
    }
  }

  .touristForm-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem 1.9rem;

    @include small-tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include medium-mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    @include small-mobile {
      gap: 0.6rem
    }
  }
}

.svg-down {
  img {
    transform: rotate(180deg);
  }
}



