@import "src/style/mixins";
@import "src/style/variables";


.profile-info-wrapper {
  padding-top: 4rem;
  padding-bottom: 10.4rem;
  @include medium-mobile {
    padding-top: 1.6rem;
    padding-bottom: 4rem;
  }
}

.profileInfo {
  display: flex;
  gap: 7rem;
  padding-top: 7rem;
  margin-bottom: 6rem;

  @include medium-mobile {
    flex-direction: column;
    padding-top: 0;
    gap: 4rem;
    margin-bottom: 4rem;
  }
}

.left {
  max-width: 20.5rem;
  width: 100%;
  max-height: 29rem;
  @include medium-mobile {
    order: 1;
    align-self: center;
    max-width: 18.7rem;
    max-height: 100%;
  }

  img {
    width: 100%;
  }
}

.right {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  @include medium-mobile {
    gap: 0.8rem;
  }
}

.subtitle {
  color: #838383;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.main-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;

  @include small-mobile {
    font-size: 1.8rem;
  }
}

.strong-text {
  color: #000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.ordinary-text {
  color: #000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.custom-block {
  display: flex;
  flex-direction: column;
}

.date {
  flex-direction: row;
  margin-bottom: 2rem;
  gap: 0.4rem;
}

.buttons-block {
  display: flex;
  gap: 0.8rem;
  width: fit-content;

  @include medium-mobile {
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
      gap: 1.2rem;
    }
  }
}