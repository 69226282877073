@import "src/style/mixins";
@import "src/style/variables";

.changePassword-container {
   >div:first-child {
    svg {
      fill:none;
      stroke:$m-blue
    }
  }
  padding-top: 4rem;
  padding-bottom: 10.4rem;
  @include small-mobile {
    padding-top: 1.6rem;
    padding-bottom: 4rem;
  }
}

.changePassword {
  max-width: 50rem;
  margin: 0 auto;
  padding-top: 5.8rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @include small-mobile {
    padding-top: 2rem;
  }
}

.buttons-block {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 0.8rem;

  @include small-mobile {
    grid-template-columns: repeat(1,1fr);
  }

  .btn-save {
    width: 100%;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  @include small-mobile {
    gap: 0.7rem;
  }
}



