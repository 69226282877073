@import "src/style/mixins";
@import "src/style/variables";

.tours {
  padding-top: 4rem;
  padding-bottom: 9rem;
  position: relative;
  height: 100%;

  @include medium-mobile {
    padding-top: 1.4rem;
    padding-bottom: 4rem;
  }
}
