@import "src/style/mixins";
@import "src/style/variables";


.title {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 2.4rem;

  @include small-mobile {
    margin-bottom: 1.8rem;
  }

  h2 {
    color: #000;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    @include small-mobile {
      font-size: 2.4rem;
    }
  }

  svg path {
    fill: inherit;
    stroke: inherit;
  }

  svg {
    width: 3.6rem;
    height: 3.6rem;
    fill: $m-blue;
    transition: 0.3s;

    @include small-mobile {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.ammountTours {
  color: $m-blue;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 140%;
}