@import "src/style/mixins";
@import "src/style/variables";

.step-3 {
  margin: 0 auto;
  max-width: 133rem;
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;

  @include small-mobile {
    flex-direction: column;
  }
  button {
    margin-bottom: 0 !important;
  }
}
.buttonCLose {
  padding: 1.6rem 2.4rem;
  background: $m-red !important;
  @include hover {
    &:hover {
      background: #8E6464FF !important;
    }
  }
}

.title {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 0.4rem;

  @include medium-mobile {
    font-size: 1.8rem;
  }

  span:first-child {
    color: $m-blackL;
    font-size: 2.4rem;
    font-weight: 700;
  }

  span:last-child {
    color: $m-blue;
    font-size: 2.4rem;
    font-weight: 700;
  }
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 160%;
  color: #6D6D6D;
  margin-bottom: 1.6rem;

  @include medium-mobile {
    font-size: 1.3rem;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin-bottom: 4.8rem;

  @include medium-mobile {
    margin-bottom: 4rem;
  }

  p:first-child {
    color: $m-blackL;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 125%;
    @include medium-mobile {
      font-size: 1.8rem;
    }
  }

  p:last-child {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 160%;
    color: #6D6D6D;

    @include medium-mobile {
      font-size: 1.3rem;
    }
  }
}

.scheme {
  background: #FAFAFA;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7rem 2.4rem;
  margin-bottom: 4rem;
  flex-direction: column;
  gap: 4rem;
  position: relative;

  @include medium-mobile {
    padding: 4.2rem 2.4rem 2.4rem;
    margin-bottom: 2.4rem;
  }
}

.busy {
  pointer-events: none;

  rect {
    fill: #DB0000;
    stroke: $m-blackL;
  }

  path {
    fill: white;
  }
}

.free {
  rect {
    fill: $white;
    stroke: #7C7C7C;
  }

  path {
    fill: $m-blackL;
  }
}

.system {
  pointer-events: none;

  rect {
    fill: #D9D9D9;
    stroke: #7C7C7C;
  }

  path {
    fill: $black;
  }
}

.choosen {
  rect {
    fill: $m-blue;
    stroke: #7C7C7C;
  }

  path {
    fill: $white;
  }
}

.customPlaces {
  display: flex;
  gap: 2.4rem;

  @include medium-mobile {
    flex-direction: column;
    align-self: flex-start;
    gap: 2.1rem;
  }

}


.place-wrapper {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  width: fit-content;

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  @include medium-mobile {
    svg {
      width: 32px;
      height: 24px;
    }
  }
}

.colorWhite {
  rect {
    fill: $white;
    stroke: $m-blackL;
  }
}

.colorRed {
  rect {
    fill: $m-red;
    stroke: $m-blackL;
  }
}

.colorGray {
  rect {
    fill: #D9D9D9;
    stroke: $m-blackL;
  }
}

.colorBlue {
  rect {
    fill: $m-blue;
    stroke: $m-blackL;
  }
}

.imageBus {
  text-align: center;

  svg {
    width: 100%;
  }

  @include large-mobile {
    margin-bottom: 4rem;
    svg {
      width: 800px;
      height: 100%;
    }
  }

  @include small-mobile {
    margin-bottom: 3rem;
  }
}

.imageWrapper {
  @include large-mobile {
    overflow-x: scroll;

    scrollbar-width: none; /* Для Firefox */

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE и Edge */
    }
  }
}

.schemeWrapper {
  width: 100%;
}

.slidecontainer {
  display: none;
  width: 100%;

  input {
    width: 100%;
    -webkit-appearance: none;
    height: 9px;
    border-radius: 999px;
    background: #E7E7E7;
    outline: none;
    transition: opacity .2s;


    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 0;
      background-image: url('/images/thumb.png');
      cursor: pointer;
      background-size: cover;
      background-color: transparent;
      box-shadow: none !important;
    }

    &::-moz-range-thumb {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 0;
      background-image: url('/images/thumb.png');
      cursor: pointer;
      background-size: cover;
      background-color: transparent;
      box-shadow: none !important;
    }
  }


  @include large-mobile {
    display: block;
  }
}

.mask {
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.historyDeleteElemModal {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  height: 100%;
  background: rgba(0, 0, 0, 0.58);
  pointer-events: none;

  &__open {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    pointer-events: initial;

    .popup-body {
      transition: 0.5s;
      transform: translate(-50%, -50%);
    }
  }

  &__popup-body {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 52rem;
    width: 100%;
    padding: 5rem 6rem 6rem 6rem;
    transform: translate(-50%, -60%);
    background: $white;
    transition: 0.5s;
    border-radius: 0.9rem;

    @include medium-mobile {
      max-width: calc(100% - 4.4rem);
    }

    @include small-mobile {
      padding: 2rem 1.5rem 3rem;
    }

    //@include small-mobile {
    //  height: 100%;
    //  max-width: 100%;
    //  padding: 2.6rem 1.6rem;
    //}
  }

  &__popup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;


    @include small-mobile {
      gap: 2.4rem;
    }
  }

  &__popup-title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 160%;
    text-align: center;
    color: $black;

    @include small-mobile {
      font-size: 1.8rem;
    }
  }

  &__popup-btnBlock {
    display: flex;
    gap: 1rem;
    max-width: 27.2rem;
    margin: 0 auto;
    width: 100%;

    .historyDeleteElemModal__popup-btn {
      margin-bottom: 0;
      max-width: 100%;
      width: 100%;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 140%;
      text-align: center;
      padding: 1.35rem 0;
      align-items: center;
      border: 1px solid $m-blue;

      @include small-mobile {
        font-size: 1.2rem;
        padding: 1.45rem 0;
      }

      @include hover {
        &:hover {
          background: $m-blueL;
          border: 1px solid $m-blueL;
          color: $white;
        }
      }
    }

    .historyDeleteElemModal__popup-btn-yes {
      background: $m-blue;
      color: $white;

    }
    .historyDeleteElemModal__popup-btn-back {
      background: $white;
      color: $black;
    }
  }
}

.alert {
  color: $m-red;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}


