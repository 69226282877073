@import "src/style/variables";
@import "src/style/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 4rem;
}

.label {
  color: $m-blackL;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.8rem;
  margin-top: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}


.textArea {
  background: #F6F6F6;
  padding: 2rem 2.4rem;
  -webkit-appearance: none;
  border-radius: 0.4rem;
  border: 1px solid #F1F1F1;
  width: 100%;
  color: $m-blackL;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  transition: .5s;
  position: relative;
  cursor: pointer;
  height: 18.6rem !important;
  resize: none;

  @include medium-mobile{
    height: 18rem !important;
  }

  @include small-app {
    padding: 1.6rem;
  }

  &:focus {
    border-color: $m-blackL;
    background: $white;
  }

  &_error {
    position: relative;
    border-color: $m-red;
    background: $white;
    color: $m-red;
  }

  &_error::placeholder {
    color: $m-red !important;
  }

  &_outline {
    background: $white;
    border: 1px solid #545454;
  }
}

.textArea::placeholder {
  color: #6D6D6D;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.textArea-fill {
  transition: 0s;
  background: $white;
  border: 1px solid $m-blue;
  font-weight: 700;
}

.error {
  color: $m-red;
  position: absolute;
  bottom: 10px;
  left: 0;
  font-size: 12px;
  line-height: 12px;
}
