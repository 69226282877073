@import "src/style/mixins";
@import "src/style/variables";

.containerError404 {
  position: relative;
  height: 100%;
}

.errorPage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleLogin {
  margin-bottom: 4rem;

  svg {
    width: 22.8rem;
    height: 100%;

    @include small-mobile {
      width: 15rem;
    }
  }
}

.image {
  margin: 0 auto 2.4rem;
  width: 35rem;

  @include small-mobile {
    width: 24rem;
  }
}

.text {
  color: $black;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 4rem;

  @include small-mobile {
    font-size: 1.4rem;
    white-space: nowrap;
  }
}