@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('ttf'),
  url('../fonts/Inter/Inter-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Bold.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Black.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}