*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

* {
  touch-action: manipulation !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;

  &.active {
    overflow-y: hidden;
  }

  font-size: 10px;
  @media screen and (max-width: 1920px) {
    font-size: calc(10vw / 19.2);
  }
  @media screen and (max-width: 1820px) {
    font-size: calc(10vw / 18.2);
  }
  @media screen and (max-width: 1750px) {
    font-size: calc(10vw / 17.5);
  }
  @media screen and (max-width: 1630px) {
    font-size: calc(10vw / 16.3);
  }
  @media screen and (max-width: 1499px) {
    font-size: calc(10vw / 14.99);
  }
  @media screen and (max-width: 1366px) {
    font-size: calc(10vw / 12.2);
  }

  @media screen and (max-width: 1200px) {
    font-size: calc(10vw / 12.0);
  }

  @media screen and (max-width: 1024px) {
    font-size: calc(10vw / 10.24);
  }

  @media screen and (max-width: 991px) {
    font-size: calc(10vw / 9.91);
  }

  @media screen and (max-width: 867px) {
    font-size: calc(10vw / 8.67 / 1.2);
  }

  @media screen and (max-width: 767px) {
    font-size: calc(10vw / 7.67 / 1.2);
  }

  @media screen and (max-width: 671px) {
    font-size: calc(10vw / 6.71 / 1.2);
  }

  @media screen and (max-width: 575px) {
    font-size: calc(10vw / 5.75);
  }

  @media screen and (max-width: 475px) {
    font-size: calc(10vw / 4.75);
  }

  @media screen and (max-width: 430px) {
    font-size: calc(10vw / 4.3);
  }

  @media screen and (max-width: 400px) {
    font-size: calc(10vw / 4.0);
  }

  @media screen and (max-width: 376px) {
    font-size: calc(10vw / 3.76);
  }

  @media screen and (max-width: 360px) {
    font-size: calc(10vw / 3.6);
  }
}

.App {
  height: 100%;
}

.privateRoute {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headers {
  position: relative;
}

body {
  font-family: 'Inter', sans-serif;
  color: $black;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin: 0 auto !important;
  position: relative;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-weight: 400;
  /*  min-height: 100vh;*/
  scroll-behavior: smooth;

  > div {
    height: 100%;
  }

  &.active {
    overflow-y: hidden;
  }

  * ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 1rem;
    background: #F3F3F3;
    border-radius: 9.9rem;
    transition: 0.5s;
  }


  * ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background: $m-blue; /* цвет полосы прокрутки */
    width: 0.4rem;
    border-radius: 9.9rem;
    height: 1rem;
    transition: 0.5s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

main {
  flex: 1 0 auto;
}

.container {
  max-width: 133rem;
  min-width: 32.8rem;
  width: calc(100% - 5rem);
  margin: 0 auto;
  height: 100%;

  @include small-mobile {
    width: calc(100% - 3.4rem);
  }
}

.container-header {
  max-width: 184rem;
  min-width: 32.8rem;
  width: calc(100% - 3.2rem);
  margin: 0 auto;
}

a,
button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

a {
  transition: 0.3s;
}

img {
  width: 100%;
}

a {
  display: inline-block;
  position: relative;
}

.btn {
  width: fit-content;
  background: $white;
  font-size: 1.4rem;
  color: $black;
  cursor: pointer;
  transition: 0.3s;
  padding: 1.2rem 2.4rem 1.2rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  grid-gap: 1.2rem;
  height: fit-content;
  border-radius: 0.4rem;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.04);

  &:disabled {
    pointer-events: none;
  }

  svg path {
    fill: inherit;
    stroke: inherit;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: $m-blue;
    transition: 0.3s;
  }

  @include hover {
    &:hover {
      transition: 0.3s;
      background: $m-blueL;
      color: $white;

      svg {
        fill: $white;
        transition: 0.3s;
      }
    }
  }

  &.active {
    background: $m-blue;
    color: $white;
    transition: 0.3s;

    svg {
      fill: $white;
      transition: 0.3s;
    }
  }
}

.btn-width {
  width: 100%;
}

.btn-second {
  padding: 1.6rem 2.4rem;
  color: $white;
  font-size: 1.5rem;
  background: $m-blue;

  @include small-mobile {
    width: 100%;
  }
}

.btn-book {
  font-size: 1.3rem;
  color: $m-green;
  padding: 0.8rem 2.4rem;
  box-shadow: none;
  border: 1px solid $m-green;

  @include hover {
    &:hover {
      background: $m-green;
      color: $white;
    }
  }

  @include small-desktop {
    padding: 0.8rem 0.8rem;
  }
}

.btn-save {
  color: $white;
  background: $m-green;
  border: 1px solid $m-green;
  padding: 1.6rem 2.4rem 1.6rem 2rem;
  box-shadow: none;
  flex-shrink: 0;

  @include hover {
    &:hover {
      background: $white;
      color: $m-green;
    }
  }
}

.btn-cancel {
  color: $m-red;
  border: 1px solid $m-red;
  padding: 1.6rem 2.4rem 1.6rem 2rem;
  box-shadow: none;
  flex-shrink: 0;

  @include hover {
    &:hover {
      background: $m-red;
      color: $white;
    }
  }
}

.btn-change-data {
  color: $white;
  background: $m-blue;
  border: 1px solid $m-blue;
  padding: 1.6rem 2.4rem 1.6rem 2rem;
  box-shadow: none;
  flex-shrink: 0;

  @include hover {
    &:hover {
      background: $m-blueL;
      border: 1px solid $m-blueL;
      color: $white;
    }
  }
}

.btn-change-password {
  color: $m-blue;
  background: $white;
  border: 1px solid $m-blue;
  padding: 1.6rem 2.4rem 1.6rem 2rem;
  box-shadow: none;
  flex-shrink: 0;

  @include hover {
    &:hover {
      background: $m-blue;
      color: $white;
    }
  }
}

.btn-load {
  width: 100%;
  border: 1px solid #9F9F9F;
  color: #9F9F9F;
  gap: 0.4rem;
  align-items: center;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @include hover {
    &:hover {
      border: 1px solid $m-blueL;
    }
  }

  svg {
    fill: #9F9F9F;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.button-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  line-height: 140%;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  justify-content: center;

  @include small-mobile {
    gap: 0.4rem;
  }
}

.btn-tab-formalization {
  padding: 1.2rem 1.6rem 1.2rem 1.2rem;
  background: #D7D7D7;
  color: #848484;

  img {
    width: 6px;
    height: 10px;
    margin-left: auto;
  }

  svg {
    display: none;
  }

  @include small-mobile {
    display: none;
  }

  .numStep {
    border: 1px solid #848484;
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: none;
    line-height: 0;
  }

  @include hover {
    &:hover {
      background: $m-green;

      .numStep {
        border: 1px solid $white;
      }
    }
  }

  &.active {
    .numStep {
      border: 1px solid $white;
    }
  }

  &.hidden {
    display: none;
  }
}

.btn-steps-open {
  background: $m-blue;
  color: $white;
  transition: 0.3s;

  .numStep {
    border: 1px solid $white;
  }
}

.btn-steps-active {
  background: $m-green;
  color: $white;
  transition: 0.3s;
  display: block;

  @include small-mobile {
    display: block;
    width: 100%;
    svg {
      display: block;
    }
  }
}

.btn-disabled {
  border: 1px solid transparent;
  color: $m-gray;
  background: rgba(91, 100, 100, 0.2);
}

.general--hidden {
  display: none;
}



