@import "src/style/mixins";
@import "src/style/variables";

.TableElem {
  display: flex;
  gap: 1.54rem;
  font-size: 1.2rem;
  padding: 0;
  border-bottom: 1px solid #E1E1E1;

  @include tablet-vert {
    gap: 1rem;
  }

  @include medium-mobile {
    flex-wrap: wrap;
    margin-bottom: 3rem;
    padding-bottom: 2.4rem;
    gap: 1.2rem;
  }

  @include small-mobile {
    gap: 1.2rem 2.3rem;
  }

  &:first-child {
    label {
      display: block;
    }
  }
}


.TableElem-block {
  display: flex;
  flex-direction: column;

  &:first-child {
    label {
      text-align: left;
    }
  }


  label {
    color: #828282;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: none;

    @include medium-mobile {
      display: block;
      font-size: 1rem;
    }
    @include small-mobile {
      text-align: left;
    }
  }
}

.TableElem-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1.2rem 0;
  gap: 0.6rem;

  @include medium-mobile {
    padding-top: 0.4rem;
    padding-bottom: 0;
  }

  @include small-mobile {
    gap: 0.4rem;
    justify-content: left;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    display: none;
    fill: $m-blue;

    @include medium-mobile {
      display: block;
    }
  }
}

.TableElem-btn {
  display: flex;
  padding: 1.2rem 0;
  align-items: center;
  flex-grow: 1;

  @include medium-mobile {
    padding: 0;
  }
  @include small-mobile {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

.TableElem-block-btn {
  order: 8;

  label {
    opacity: 0;
  }

  @include medium-mobile {
    max-width: 100%;
    width: calc(25% - 1rem);
    align-items: center;
  }
  @include small-mobile {
    padding: 0;
    width: 47%;
    label {
      display: none !important;
    }
  }
}

.custom-text {
  color: $m-blackL;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.title {
  max-width: 30.8rem;
  width: 100%;
  order: 1;

  @include medium-mobile {
    max-width: 100%;
    label {
      display: none !important;
    }
  }

  a {
    color: $black;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-decoration: underline;
    transition: 0.3s;
    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
      }
    }
  }
}

.date {
  max-width: 16rem;
  width: 100%;
  order: 2;

  @include small-desktop {
    max-width: 14rem;
  }

  @include tablet-vert {
    max-width: 11rem;
  }

  @include large-mobile {
    max-width: 8rem;
  }

  @include medium-mobile {
    max-width: 100%;
    width: calc(32%);
  }

  @include small-mobile {
    width: calc(53% - 2.4rem);
  }
}

.duration {
  max-width: 11rem;
  width: 100%;
  order: 3;

  @include large-mobile {
    max-width: 8rem;
  }
  @include medium-mobile {
    max-width: 100%;
    width: calc(32%);
  }
  @include small-mobile {
    width: calc(50% - 2.4rem);
  }
}

.price {
  max-width: 19.8rem;
  width: 100%;
  order: 4;

  svg {
    fill: none;
    stroke: $m-blue;
  }

  @include small-desktop {
    max-width: 14rem;
  }

  @include tablet-vert {
    max-width: 8rem;
  }

  @include large-mobile {
    max-width: 8rem;
  }
  @include medium-mobile {
    max-width: 100%;
    width: calc(32%);
  }
  @include small-mobile {
    width: calc(53% - 2.4rem);
  }
}

.route {
  max-width: 12.2rem;
  width: 100%;
  order: 5;

  @include large-mobile {
    max-width: 6.2rem;
  }

  @include medium-mobile {
    max-width: 100%;
    width: calc(25% - 1rem);
  }
  @include small-mobile {
    order: 7;
    width: calc(53% - 2.4rem);
    label {
      display: none !important;
    }
  }
}

.link {
  display: block;
  padding: 0.6rem 0.4rem;
  border-bottom: 2px dashed #BBB;
  transition: 0.3s;
  cursor: pointer;

  @include hover {
    &:hover {
      transition: 0.3s;
      color: $m-blue;
      border-bottom: 2px dashed $m-blue;
    }
  }
}

.commission {
  max-width: 10rem;
  width: 100%;
  font-weight: 700;
  color: $m-green;
  order: 6;

  @include small-desktop {
    max-width: 6rem;
  }
  @include medium-mobile {
    max-width: 100%;
    width: calc(22% - 1rem);
  }
  @include small-mobile {
    order: 5;
  }
}


.seats {
  max-width: 9rem;
  width: 100%;
  font-weight: 700;
  order: 7;

  @include large-mobile {
    max-width: 6rem;
  }
  @include medium-mobile {
    max-width: 100%;
    width: calc(25% - 1rem);
  }
  @include small-mobile {
    order: 6;
  }
}

.first-elem {
  display: flex;
  justify-content: flex-start;
}