@import "src/style/mixins";
@import "src/style/variables";

.change-profile {
  padding-top: 4rem;
  padding-bottom: 10.4rem;
  @include small-mobile {
    padding-top: 1.6rem;
    padding-bottom: 4rem;
  }
}

.change-profile-info {
  padding-bottom: 6rem;
  padding-top: 7rem;
  display: flex;
  gap: 7rem;

  @include small-tablet {
    justify-content: space-around;
  }

  @include medium-mobile {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    gap: 4rem;
    margin-bottom: 4rem;
  }
}

.change-profile-info-left {
  max-width: 20.5rem;
  flex-shrink: 0;

  @include medium-mobile {
    order: 1;
    align-self: center;
    max-width: 18.7rem;
    max-height: 100%;
  }
}

.change-profile-info-right {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem 1.7rem;
  width: 100%;

  @include small-tablet {
    grid-template-columns: repeat(1, 1fr);
    max-width: 50rem;
  }
}

.buttons-block {
  display: flex;
  gap: 0.8rem;
  margin: 0 auto;
  width: fit-content;

  @include medium-mobile {
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
      gap: 1.2rem;
    }
  }
}

.image-block {
  margin-bottom: 0.8rem;
}

.imageLoad {
  position: relative;
  cursor: pointer;


  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    @include hover {
      &:hover {
        transition: 0.3s;
        background: $m-blueL;
        color: $white;

        svg {
          fill: $white;
          transition: 0.3s;
        }
      }
    }
  }
}