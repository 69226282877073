@import "src/style/mixins";
@import "src/style/variables";

.tabs-step-wrapper {
  overflow-x: scroll;

  position: relative;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
}

.tabs-step {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 4rem;

  @include small-mobile {
    margin-bottom: 2.4rem;
  }

  button {
    flex-shrink: 0;
    box-shadow: none;
  }
}