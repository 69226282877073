@import "src/style/mixins";
@import "src/style/variables";

.table-history-elem-wrapper {
  &:first-child {
    label {
      display: block;

      @include large-mobile {
        display: none;
      }
    }
  }
}

//.table-history-elem-wrapper {
//  margin-bottom: 2.4rem;
//}


.table-history-elem {
  display: flex;
  gap: 3rem;
  font-size: 1.2rem;
  padding: 0;
  border-bottom: 1px solid #E1E1E1;
  @include small-desktop-min {
    gap: 1.5rem;
  }

  @include large-mobile {
    flex-wrap: wrap;
    border-bottom: none;
  }

  @include small-mobile {
    gap: 1.4rem;
  }

}

.TableElem-block {
  display: flex;
  flex-direction: column;


  label {
    color: #828282;
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: none;
    margin-bottom: 0.8rem;

    @include large-mobile {
      display: none;
    }
  }
}

.TableElem-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1.6rem 0;
  gap: 0.4rem;

  svg {
    display: none;
    width: 1.4rem;
    height: 1.4rem;
    fill: $m-blue;
  }

  @include small-tablet {
    line-height: 130%;
  }

  @include large-mobile {
    padding: 0;
    justify-content: left;
    svg {
      display: block;
    }
  }
}

.tableElem-files {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  transition: 0.3s;

  @include small-mobile {
    justify-content: center;
    background: #f8f8f8;
    padding: 1.2rem;
    font-size: 1.3rem;
    border-bottom: 1px solid #e1e1e1;
  }

  @include hover {
    &:hover {
      transition: 0.3s;
      color: $m-blue;
    }
  }

  img {
    width: 0.7rem;
    height: 0.4rem;
    transition: 0.3s;

    @include small-mobile {
      width: 1.2rem;
      height: 0.6rem;
    }
  }

  span {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    color: $black;
  }
}

.tableElem-tourRoute {
  cursor: pointer;
}

.TableElem-btn {
  display: flex;
  padding: 1.2rem 0;
  align-items: center;
  flex-grow: 1;
}

.TableElem-block-btn {
  label {
    opacity: 0;
  }
}

.custom-text {
  color: $m-blackL;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}


.status {
  max-width: 11.4rem;
  width: 100%;

  @include small-tablet {
    max-width: 8.4rem;
  }

  @include small-mobile {
    max-width: 11.4rem;
  }

  .statusBtn {
    display: flex;
    align-items: center;
    color: $white;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 100%;
    width: 100%;
    padding: 0.8rem;
    justify-content: center;
    border-radius: 0.4rem;
  }
}

.color1 {

}

.color2 {
  background: #06B9B9;
}

.color4 {
  background: $m-blue;
}

.color5 {
  background: $m-red;
}

.color7 {
  background: $m-green;
}

.color8 {
  background: #FCAF1A;
}

.ordersNumber {
  max-width: 11.4rem;
  width: 100%;
  color: $black;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 160%;

  @include small-tablet {
    max-width: 8.4rem;
  }
}

.title {
  max-width: 50.8rem;
  width: 100%;

  @include large-mobile {
    max-width: 100%;
  }

  a {
    color: $black;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-decoration: underline;
    transition: 0.3s;
    align-self: flex-start;

    @include small-mobile {
      font-size: 1.4rem;
    }
    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
      }
    }
  }
}

.files {
  max-width: 5.2rem;
  width: 100%;

  @include large-mobile {
    order: 1;
  }
  @include small-mobile {
    max-width: 100%;
  }


  &__icon {
    display: none;
    width: 1.6rem !important;
    height: 1.6rem !important;
    @include small-mobile {
      display: block;
    }
  }
}

.date {
  max-width: 16.6rem;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 100%;

  @include small-tablet {
    max-width: 13.6rem;
  }

  @include large-mobile {
    max-width: 16.6rem;
    label {
      display: block !important;
      text-align: left;
    }
  }

  @include small-mobile {
    label {
      font-size: 1rem;
    }
  }
}

.arrow__active {
  transform: rotateX(180deg);
  transition: 0.3s;
}

.details {
  max-width: 9rem;
  width: 100%;

  @include small-mobile {
    margin-left: auto;
  }
}

.link {
  display: flex;
  padding: 0.6rem 0.4rem;
  border-bottom: 2px dashed #BBB;
  transition: 0.3s;
  flex-shrink: 0;

  @include hover {
    &:hover {
      transition: 0.3s;
      color: $m-blue;
      border-bottom: 2px dashed $m-blue;
    }
  }
}


.downloadAct {
  max-width: 10.2rem;
  width: 100%;

  @include small-mobile {
    max-width: 100%;
    width: calc(50% - 1.4rem)
  }
}

.downloadCount {
  max-width: 10.8rem;
  width: 100%;

  @include small-mobile {
    max-width: 100%;
    width: calc(50% - 1.4rem)
  }
}

.downloadBtn {
  flex-shrink: 0;
  padding: 0.6rem 0.8rem;
  gap: 0.4rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 100%;
  background: #F8F8F8;
  box-shadow: none;

  @include small-mobile {
    padding: 1.2rem;
    font-size: 1.3rem;
    width: 100%;
  }

  div {
    flex-shrink: 0;
  }

  svg {
    fill: #F8F8F8;
    stroke: $m-blue;
    width: 1.4rem;
    height: 1.4rem;
  }

  @include hover {
    &:hover {
      svg {
        stroke: $white;
        fill: $m-blueL;
      }
    }
  }
}












