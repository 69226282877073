@mixin large-desktop {
    @media screen and (max-width: 1750px) {
        @content;
    }
}
@mixin medium-desktop {
    @media screen and (max-width: 1630px) {
        @content;
    }
}
@mixin desktop {
    @media screen and (max-width: 1499px) {
        @content;
    }
}
@mixin small-desktop {
    @media screen and (max-width: 1366px) {
        @content;
    }
}
@mixin small-desktop-min {
    @media screen and (max-width: 1220px) {
        @content;
    }
}
@mixin tablet-vert {
    @media screen and (max-width: 1024px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 991px) {
        @content;
    }
}
@mixin medium-tablet {
    @media screen and (max-width: 867px) {
        @content;
    }
}
@mixin small-tablet {
    @media screen and (max-width: 767px) {
        @content;
    }
}
@mixin large-mobile {
    @media screen and (max-width: 671px) {
        @content;
    }
}
@mixin medium-mobile {
    @media screen and (max-width: 575px) {
        @content;
    }
}
@mixin small-mobile {
    @media screen and (max-width: 475px) {
        @content;
    }
}

@mixin small-app {
    @media screen and (max-width: 376px) {
        @content;
    }
}
@mixin small-mobile-min() {
    @media screen and (max-width: 361px) {
        @content;
    }
}
@mixin smaller-mobile-min() {
    @media screen and (max-width: 320px) {
        @content;
    }
}

@mixin triangle($width, $dir, $color) {
    @if $dir == up {
        width: 0;
        height: 0;
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-bottom: $width/2 solid $color;
    } @else if $dir == down {
        width: 0;
        height: 0;
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-top: $width/2 solid $color;
    } @else if $dir == right {
        width: 0;
        height: 0;
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
        border-left: $width solid $color;
    } @else if $dir == left {
        width: 0;
        height: 0;
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
        border-right: $width solid $color;
    }
}

@mixin hover {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}
