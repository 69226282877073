@import "src/style/mixins";
@import "src/style/variables";


.buttonTop {
  position: fixed;
  right: 5%;
  top: 50%;
  width: 6rem;
  height: 6rem;
  background: $m-blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;

  @include medium-mobile {
    width: 4.4rem;
    height: 4.4rem;
  }

  @include hover {
    &:hover {
      transition: 0.3s;
      background: $m-blueL;

      svg {
        fill: $white;
        transition: 0.3s;
      }
    }
  }
}

.arrow {
  width: 1.6rem;

  @include medium-mobile {
    width: 1.2rem;
  }
}
