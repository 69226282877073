@import "src/style/mixins";
@import "src/style/variables";

.mobile-header {
  width: 100%;
  position: sticky;
  top: 0;
  background: #FBFBFB;
  align-items: center;
  display: none;
  z-index: 20;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;

  @include small-tablet {
    display: block;
  }
}

.mobile-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-mb {
  max-width: 11.2rem;
}

.burger {
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .burgerSvg {
    width: 1.6rem;
    height: 1.6rem;
  }

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 140%;
  }
}

.active-menu {
  position: absolute;
  top: 100%;
  width: 100%;
  min-height: 100vh;
  background: $white;
  visibility: hidden;
  transform: translateX(110%);
  z-index: 20;
  transition: 0.3s;
  height: 100%;
}

.active-menu-open {
  visibility: visible;
  transform: translateX(0%);
  transition: 0.3s;
}

.burger-close {
  width: 2.8rem;
  height: 2.8rem;
}

.active-menu-inner {
  padding-top: 2rem;
  padding-bottom: 13rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.background-white {
  background: $white;
}

.background-gray {
  background: #FBFBFB;
}

.name {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 2.4rem;

  span {
    font-size: 1.2rem;
    font-style: normal;
    line-height: 140%;
  }

  span:first-child {
    color: #858585;
    font-weight: 500;
  }

  span:last-child {
    font-size: 1.4rem;
    color: $black;
    font-weight: 700;
  }
}

.title {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  color: #CBCBCB;
  margin-bottom: 1.2rem;
}

.tabs-wrapper {
  flex: 1 0 auto;
}

.tabs {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    gap: 1.2rem;
    border-bottom: 1px solid #E1E1E1;

    span {
      color: $m-blackL;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 140%;
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
      stroke: $m-blue;
    }
  }
}

.exit {
  box-shadow: none;
  line-height: 140%;
  margin: 5rem auto 0;

  svg {
    fill: none;
    stroke: $m-blackL;
  }
}

.notification {
  position: relative;
  background: none;
  box-shadow: none;
  padding: 0;
  margin-left: auto;
  margin-right: 3rem;

  @include hover {
    &:hover {
      transition: 0.3s;
      color: $m-blue;
      svg {
        stroke: $m-blue;
        transition: 0.3s;
      }
    }
  }

  span {
    position: absolute;
    right: -1rem;
    top: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: $m-blueL;
    border-radius: 50%;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: $white;
  }

  svg {
    width: 2rem;
    height: 2rem;
    stroke: $black;
    fill: none;
    transition: 0.3s;
  }
}
