@import "src/style/mixins";
@import "src/style/variables";


.loadmore {
  position: absolute;
  bottom: 5rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.spinner {
  width: 2.4rem;
  height: 2.4rem;
}

.text {
  color: $m-blackL;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}