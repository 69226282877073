@import "src/style/mixins";
@import "src/style/variables";

.successPage {
  position: relative;
  min-height: 77vh;
}

.successImage {
  width: 9rem;
  height: 9rem;

  @include medium-mobile {
    width: 6.4rem;
    height: 6.4rem;
  }
}

.wrapper {
  max-width: 46.6rem;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include medium-mobile {
    max-width: 28rem;
  }
}

.wrapper-inner {
  position: relative;
}

.successImage {
  margin: 0 auto 1.2rem;
}

.downloadBtn {
  max-width: 21rem;
  width: 100%;
  padding: 1.85rem 4.35rem 1.85rem 4rem;
  background: $m-yellow;

  @include medium-mobile {
    max-width: 100%;
    padding: 1rem 4.35rem 1rem 4rem;
  }

  div {
    gap: 1.05rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    stroke: $m-blackL;
    fill: $m-yellow;
  }

  @include hover {
    &:hover {
      svg {
        stroke: $white;
        fill: $m-blueL;
      }
    }
  }
}

.return-container {
  display: flex;
  justify-content: center;
  a {
    @include medium-mobile {
      width: 100%;
    }
  }

}

.return {
  background: $m-green;
  color: $white;
  line-height: 160%;
  padding: 1.6rem 2.4rem 1.6rem 2rem;
  font-size: 1.5rem;
  font-weight: 500;

  @include medium-mobile {
    width: 100%;
    padding: 1rem 2.4rem 1rem 2rem;
  }

  div {
    height: 160%;
  }
}

.title {
  color: $black;
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  margin-bottom: 3.2rem;

  @include medium-mobile {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }
}

.download {
  display: flex;
  gap: 0.8rem;
  margin: 0 auto 7rem;
  justify-content: center;

  @include medium-mobile {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.8rem;
  }
}

.bg-1 {
  position: absolute;
  width: 12.2rem;
  height: 12.2rem;
  rotate: -30deg;
  opacity: 0.5;
  top: -12rem;
  left: -12rem;

  @include small-app {
    top: -16rem;
    left: -4rem;
  }
}

.bg-2 {
  position: absolute;
  width: 11rem;
  height: 11rem;
  rotate: 30deg;
  opacity: 0.5;
  top: -3.3rem;
  right: -19rem;
  @include medium-mobile {
    right: -14rem;
  }

  @include small-app {
    right: -7rem;
    top: -10.3rem;
  }
}

.bg-3 {
  position: absolute;
  width: 12.7rem;
  height: 12.7rem;
  rotate: 30deg;
  opacity: 0.3;
  bottom: -19rem;
  right: -10rem;

  @include small-app {
    bottom: -15rem;
    right: -4rem;
  }
}

.bg-4 {
  position: absolute;
  width: 27.7rem;
  height: 27.7rem;
  rotate: -15deg;
  opacity: 0.3;
  left: -42rem;
  bottom: -22rem;

  @include medium-mobile {
    left: -30rem;
  }

  @include small-app {
    bottom: -32rem;
    left: -17rem;
  }
}
