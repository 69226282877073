@import "src/style/mixins";
@import "src/style/variables";


.form {
  margin-bottom: 3.2rem;
}

.tourSelect {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 0.8rem;
  @include medium-mobile {
    display: none;
  }

}

.blockFilter {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: right;

  button {
    padding: 1.6rem 2.7rem;
    >div {
      gap: 1rem;
    }
  }

  @include medium-mobile {
    display: none;
  }

  .clearSelect {
    font-size: 1.2rem;
    box-shadow: none;
    @include hover {
      &:hover {
        color: $m-blue;
        background: none;
      }
    }

    svg {
      stroke: red;
      fill: none;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
}


.tourSelect-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 20;
  visibility: hidden;
  transform: translateX(110%);
  transition: 0.3s;

  .tourSelect,.blockFilter {
    display: flex;
    flex-direction: column;
  }
}

.tourSelect-mobile-active {
  visibility: visible;
  transform: translateX(0);
  transition: 0.3s;
}

.mobile-steps-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 2.6rem;
  padding-bottom: 2.4rem;
}

.burger {
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .burgerSvg {
    width: 1.6rem;
    height: 1.6rem;
  }

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 140%;
  }
}

.burger-close {
  width: 2.8rem;
  height: 2.8rem;
}

.tabsStep-mobile-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1.6rem;
}

.steps-mb-logo {
  max-width: 11.2rem;
}

.filter {
  display: none;
  width: 100%;
  align-items: center;
  padding: 1.6rem;
  border-radius: 0.4rem;
  background: $m-blue;
  gap: 0.8rem;
  color: $white;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 2.3rem;

  img {
    width: 2rem;
    height: 2rem;
  }

  @include medium-mobile {
    display: flex;
  }
}






