@import "src/style/mixins";
@import "src/style/variables";

.history-details {
  button {
    margin-bottom: 2.4rem;
  }
}


.title {
  color: #CBCBCB;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 2.4rem;
  padding-top: 2.4rem;
}

.final-order-titleBlock {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: 3.6rem;
}

.tour-date {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  color: $m-blackL;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 140%;

  @include small-mobile {
    font-size: 1.4rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: $m-blue;
  }
}

.tour-title {
  color: $black;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;

  @include small-mobile {
    font-size: 1.8rem;
  }
}

.final-order-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10rem 10.3rem;
  margin-bottom: 6rem;

  @include tablet-vert {
    grid-template-columns: repeat(2, 1fr);
  }

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 4.1rem;
  }
}


.order-elem-title {
  color: $m-blue;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include small-mobile {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}

.order-elem-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  @include small-mobile {
    margin-bottom: 1.2rem;
  }
}

.order-elem-item {
  display: flex;
  align-items: center;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #F0F0F0;
  justify-content: space-between;

  @include small-mobile {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &:last-child {
    border-bottom: none;
  }

  span:first-child {
    color: #838383;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 100%;

    @include small-mobile {
      font-size: 1.1rem;
    }
  }

  span:last-child {
    color: $m-blackL;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 140%;

    @include small-mobile {
      font-size: 1.2rem;
    }
  }
}

.additional button {
  margin: 0 auto 2.4rem;
}

.additional-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 88.1rem;
  gap: 1.9rem;
  margin-bottom: 2.4rem;

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
    margin-bottom: 0.4rem;
  }
}


.title {
  color: #CBCBCB;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 2.4rem;

  @include medium-mobile {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
}

.paymentBlock-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.9rem;

  @include small-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
  }
}

.paymentBlock {
  margin-bottom: 4.8rem;

  @include medium-mobile {
    margin-bottom: 4rem;
  }
}

.paymentBlock-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 1.2rem;

  @include medium-mobile {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.input-payment {
  input {
    padding-left: 2.4rem !important;

    @include medium-mobile {
      padding-left: 1.4rem !important;
    }
  }
}

.successBtn {
  background: $m-green;
  color: $white;

  svg {
    fill: $m-green;
    stroke: $white;
  }

  @include hover {
    &:hover {
      background: $m-blueL;

      svg {
        fill: $m-blueL;
      }
    }
  }
}

.fieldHistory {
  label {
    display: flex !important;
    flex-direction: column;
  }

  .label {
    color: $m-blackL;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 0.8rem;
    margin-top: 1.2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .fieldHistoryWrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: $white;
    padding: 2rem 2.4rem 1.8rem;
    border-radius: 0.4rem;
    border: 1px solid $m-blue;
    width: 100%;
    color: $m-blackL;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    position: relative;

    @include small-app {
      padding: 1.6rem;
    }
  }

  .textarea {
    height: 18.6rem;
    align-items: flex-start;
    margin-bottom: 4rem;
  }

  svg {
    width: 1.7rem;
    height: 1.7rem;
    fill: $m-blue;
    z-index: 1;
  }
}

.paymentBlock-title {
  color: #CBCBCB;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 2.4rem;
}

.place-wrapper {
  display: flex;
  gap: 3.2rem;
  align-items: center;
  width: fit-content;

  @include small-mobile {
    gap: 2.5rem;
  }

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.place-wrapper:global(.place-wrapper--hidden) {
  display: none;
}

.place {
  position: relative;
  width: 3.4rem;

  @include medium-tablet {
    width: 4rem;
  }

  @include large-mobile {
    width: 5rem;
  }

  @include small-mobile {
    width: 3.4rem;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

.firstBlock {
  position: relative;
}

.message {
  width: 100%;
  top: 100%;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: $m-blue;
}

.buttonsWrapper {
  display: flex;
  gap: 0.6rem;
  width: fit-content;
}

.buttonHistory {
  width: 2rem;
  height: 2rem;
  box-shadow: none;
  margin-bottom: 0 !important;
  padding: 0;

  @include hover {
    &:hover {
      background: transparent;

      svg {
        fill: $m-red;
        opacity: 0.6;
      }
    }
  }

  svg {
    fill: $m-red;
  }
}


.historyDeleteElemModal {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  height: 100%;
  background: rgba(0, 0, 0, 0.58);
  pointer-events: none;

  &__open {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    pointer-events: initial;

    .popup-body {
      transition: 0.5s;
      transform: translate(-50%, -50%);
    }
  }

  &__popup-body {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 52rem;
    width: 100%;
    padding: 5rem 6rem 6rem 6rem;
    transform: translate(-50%, -60%);
    background: $white;
    transition: 0.5s;
    border-radius: 0.9rem;

    @include medium-mobile {
      max-width: calc(100% - 4.4rem);
    }

    @include small-mobile {
      padding: 2rem 1.5rem 3rem;
    }

    //@include small-mobile {
    //  height: 100%;
    //  max-width: 100%;
    //  padding: 2.6rem 1.6rem;
    //}
  }

  &__popup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;


    @include small-mobile {
      gap: 2.4rem;
    }
  }

  &__popup-title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 160%;
    text-align: center;
    color: $black;

    @include small-mobile {
      font-size: 1.8rem;
    }
  }

  &__popup-btnBlock {
    display: flex;
    gap: 1rem;
    max-width: 27.2rem;
    margin: 0 auto;
    width: 100%;

    .historyDeleteElemModal__popup-btn {
      margin-bottom: 0;
      max-width: 100%;
      width: 100%;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 140%;
      text-align: center;
      padding: 1.35rem 0;
      align-items: center;
      border: 1px solid $m-blue;

      @include small-mobile {
        font-size: 1.2rem;
        padding: 1.45rem 0;
      }

      @include hover {
        &:hover {
          background: $m-blueL;
          border: 1px solid $m-blueL;
          color: $white;
        }
      }
    }

    .historyDeleteElemModal__popup-btn-yes {
      background: $m-blue;
      color: $white;

    }
    .historyDeleteElemModal__popup-btn-back {
      background: $white;
      color: $black;
    }
  }
}

.blockButton {
  opacity: 0.7;
  pointer-events: none;
}










