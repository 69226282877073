@import "src/style/mixins";
@import "src/style/variables";

.label {
  color: $m-blackL;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.8rem;
  margin-top: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.input {
  background: #F6F6F6;
  padding: 2.1rem 2.4rem 1.9rem;
  -webkit-appearance: none;
  border-radius: 0.4rem;
  border: 1px solid #F1F1F1;
  width: 100%;
  color: $m-blackL;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  transition: .5s;
  position: relative;
  cursor: pointer;

  @include small-app {
    padding: 1.6rem;
  }

  &:focus {
    border-color: $m-blackL;
    background: $white;
  }

  &_error {
    position: relative;
    border-color: $m-red !important;
    background: $white !important;
    color: $m-red !important;
  }

  &_error::placeholder {
    color: $m-red !important;
  }

  &_outline {
    background: $white;
    border: 1px solid #545454;
  }

  @include hover {
    &:hover {
      background: $white;
      border: 1px solid $m-blue;
      transition: 0.3s;
    }
  }
}

.input::placeholder {
  color: #6D6D6D;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 1;
}

.inputBlock {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    top: 48%;
    width: 2rem;
    height: 2rem;
    left: 2.4rem;
    transform: translateY(-50%);
    fill: #6D6D6D;
    z-index: 1;

    @include small-mobile {
      left: 1.5rem;
    }
  }
}

.input-fill {
  transition: 0s;
  background: $white !important;
  border: 1px solid $m-blue !important;
  font-weight: 700 !important;
}

.inputBlockSvg {
  padding-left: 5.2rem;

  @include small-mobile {
    padding-left: 4.2rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
}

.selectInputBlock {
  label {
    width: 100%;
  }

  input {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: none;
    font-weight: 700;
    font-size: 1.5rem;

    @include hover {
      &:hover {
        background: $white;
        border: 1px solid $m-blue;
        transition: 0.3s;
      }
    }

    &:focus {
      transition: 0s;
      background: $white;
      border: 1px solid $m-blue;
    }
  }

  input::-webkit-inner-spin-button {
    display: none;
  }

  .input-date-container {
    position: relative;

    svg {
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);
      fill: $m-blue;

      @include small-mobile {
        left: 1.5rem;
      }
    }
  }

  .input-date {
    padding-left: 5.2rem;
    padding-top: 2.1rem;
    padding-bottom: 1.9rem;

    @include small-mobile {
      padding-left: 4.2rem;
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
  }
}

.selectSpan {
  color: #6D6D6D;
  pointer-events: none;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  left: 2.4rem;
  z-index: 1;
  font-size: 1.4rem;
}

.selectSpanActive {
  color: $m-blue;
  pointer-events: none;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  left: 2.4rem;
  z-index: 1;
  font-size: 1.4rem;
}


.hide-password-block {
  position: relative;
}

.hide-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2.4rem;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  padding: 0;

  @include small-mobile {
    right: 1.4rem;
  }

  @include hover {
    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }
  }


  img {
    width: 100%;
    height: 100%;
  }
}

.grid-item-1 {
  grid-row: 1;
  grid-column: span 1; /* указываем, что элемент должен занимать одну колонку */
}

/* стили для элементов во второй строке */
.grid-item-2 {
  grid-row: 2;
  grid-column: span 1; /* указываем, что элемент должен занимать одну колонку */
}

.error {
  padding-top: 0.5rem;
  //position: absolute;
  left: 0;
  font-size: 1.4rem;
  line-height: 140%;
  color: $m-red;
}
