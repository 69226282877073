@import "src/style/mixins";
@import "src/style/variables";

.box {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2.4rem;
}

.custom_checkbox {
  cursor: pointer;
  transition: 0.3s;
}

.custom_checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom_checkbox > span {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 160%;
  color: $black;
}

.custom_checkbox span {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom_checkbox > span::before {
  content: '';
  display: inline-block;
  width: 2rem;
  height: 2rem;
  padding: 0.3rem;
  border: 1px solid $m-blackL;
  box-sizing: border-box;
  border-radius: 0.2rem;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.8em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background: $white;

  @include medium-mobile {
    margin-right: 0.6em;
  }
}

/* стили при наведении курсора на checkbox */
.custom_checkbox > input:not(:disabled):not(:checked) + span:hover::before {
/*  border-color: #b3d7ff;*/
  cursor: pointer;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom_checkbox > input:not(:disabled):active + span::before {
  background-color: $m-blackL;
  border-color: $m-blackL;
}

/*!* стили для чекбокса, находящегося в фокусе *!
.custom_checkbox > input:focus + span::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}*/

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom_checkbox > input:focus:not(:checked) + span::before {
  border-color: $m-blackL;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom_checkbox > input:checked + span::before {
  border: 1px solid $m-blackL;
  box-sizing: border-box;
  background: $m-blackL content-box;
  //background-color: #0b76ef;
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom_checkbox > input:disabled + span::before {
  background-color: #e9ecef;
}
