@import "src/style/mixins";
@import "src/style/variables";

.history-wrapper {
  padding-top: 4rem;
  padding-bottom: 10.4rem;
  @include small-mobile {
    padding-top: 1.6rem;
    padding-bottom: 4rem;
  }
}

.tableHistory {
  display: flex;
  flex-direction: column;
  gap: 0;

  span {
    font-size: 1.6rem;
    font-weight: 400;
  }

  @include large-mobile {
    gap: 2.4rem;
  }
}

.tabs-wrapper {
  overflow-x: scroll;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
}

.tabs {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 4rem;
  @include small-mobile {
    display: none;
  }

}

.btnCustom {
  padding: 0.8rem 1.6rem;
  background: #FAFAFA;
  color: #727272;
  height: 160%;
  box-shadow: none;
  flex-shrink: 0;
}

.btnActive {
  padding: 0.8rem 1.6rem;
  background: #FAFAFA;
  color: $white;
  height: 160%;
  background: $m-blue;
  flex-shrink: 0;
}

.tabsStep-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 20;
  visibility: hidden;
  transform: translateX(110%);
  transition: 0.3s;

  button {
    @include small-mobile {
      display: block;
      width: 100%;
      background: none;
      padding: 2rem 0;
      box-shadow: none;
      color: $m-blackL;
      border-bottom: 1px solid rgb(225, 225, 225);
      font-size: 1.6rem;
    }
    @include hover {
      &:hover {
        background: none;
        color: $m-blackL;
      }
    }
  }

  .tabs {
    flex-direction: column;
    display: flex;
    gap: 0;
  }
}

.tabsStep-mobile-wrapper {
  flex-direction: column;
}

.steps-mobile-active {
  visibility: visible;
  transform: translateX(0);
  transition: 0.3s;
}

.mobile-steps-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 2.6rem;
  padding-bottom: 2.4rem;
}

.burger {
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .burgerSvg {
    width: 1.6rem;
    height: 1.6rem;
  }

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 140%;
  }
}

.burger-close {
  width: 2.8rem;
  height: 2.8rem;
}

.tabsStep-mobile-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1.6rem;
}

.steps-mb-logo {
  max-width: 11.2rem;
}

.filter {
  display: none;
  width: 100%;
  align-items: center;
  padding: 1.6rem;
  border-radius: 0.4rem;
  background: $m-blue;
  gap: 0.8rem;
  color: $white;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 2.3rem;

  img {
    width: 2rem;
    height: 2rem;
  }

  @include small-mobile {
    display: flex;
  }
}













