//color
$black: #000000;
$m-blackL: #232323;;
$white: #ffffff;
$m-blue: #0062AA;
$m-blueL: #00BDEB;
$m-gray: #5B6464;
$m-red: #DD0000;
$m-green: #0C9900;
$m-yellow: #E3E735
