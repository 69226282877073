@import "src/style/mixins";
@import "src/style/variables";

.tourFormalization {
  height: 100%;
  >div:first-child  {
    svg {
      fill: white;
      stroke: $m-blue;
    }
  }
}

.tabsStep-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 20;
  visibility: hidden;
  transform: translateX(110%);
  transition: 0.3s;

  button {
    @include small-mobile {
      display: block;
      width: 100%;
    }
  }
}

.tabsStep-mobile-wrapper {
  flex-direction: column;
}

.steps-mobile-active {
  visibility: visible;
  transform: translateX(0);
  transition: 0.3s;
}

.mobile-steps-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 2.6rem;
  padding-bottom: 2.4rem;
}

.burger {
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .burgerSvg {
    width: 1.6rem;
    height: 1.6rem;
  }

  span {
    color: $black;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 140%;
  }
}

.burger-close {
  width: 2.8rem;
  height: 2.8rem;
}

.tabsStep-mobile-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1.6rem;
}

.steps-mb-logo {
  max-width: 11.2rem;
}
