@import "src/style/mixins";
@import "src/style/variables";


.notification {
  position: absolute;
  top: 100%;
  max-width: 59.7rem;
  width: 100%;
  right: 0;
  z-index: 100;
  background: $white;
  box-shadow: -4px 0 10px 0 rgba(0, 0, 0, 0.04);
  min-height: 100dvh;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(110%);

  &__active {
    visibility: visible;
    transform: translate(0%);
    overflow-y: auto;
  }

  &__top {
    padding: 2rem 4rem 1.1rem 1.5rem;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include medium-mobile  {
      padding: 1.6rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 140%;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;

    img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__close {
    color: #565656;
    width: 3.4rem;
    height: 3.4rem;
    transition: 0.3s;
    cursor: pointer;

    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: 80dvh;
    overflow-y: auto;
    padding-bottom: 3rem;
  }

  &__select {
    position: relative;

    &.notification__select-active {
      .notification__titleSelect {
        img {
          transform: rotateX(180deg);
          transition: 0.3s;
        }
      }
      .notification__openSelect {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
      }
    }
  }

  &__titleSelect {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    color: #34485c;
    padding: 2.1rem 2.1rem 1.2rem 1.9rem;
    cursor: pointer;
    width: fit-content;

    span {
      margin-right: 0.5rem;
    }

    img {
      width: 1.4rem;
      height: 1.8rem;
      transition: 0.3s;
    }
  }

  &__openSelect {
    position: absolute;
    top: 88%;
    left: 1.9rem;
    background: $white;
    border: 1px solid rgba(0, 98, 170, 0);
    border-radius: 0.4rem;
    box-shadow: 0 14px 34px 0 rgba(0, 0, 0, 0.08);
    z-index: 20;
    visibility: hidden;
    opacity: 0;
  }

  &__shoosenSelect {
    display: flex;
    gap: 0.6rem;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    color: $black;
    align-items: center;
    padding: 1rem 3.5rem 1rem 1rem;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 0.4rem;

    @include hover {
      &:hover {
        transition: 0.3s;
        background: #f6f6f6;
      }
    }

    img {
      width: 1rem;
      height: 0.7rem;
    }
  }
}

.notification-elem {
  padding: 1.6rem 4rem 1.6rem 2rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 160%;
  color: $black;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  transition: 0.5s;

  @include medium-mobile  {
    padding: 1.6rem 2.2rem 1.6rem 2rem;
  }

  &__active {
    background: rgba(0, 98, 170, 0.03);
  }

  &__created {
    font-size: 1.3rem;
    font-weight: 500;
    color: #818585;
  }

  &__title {
    @include small-mobile  {
      max-width: 37rem;
    }
  }

  &__date {
    margin-bottom: 0.9rem;

    span:first-child {
      margin-right: 0.5rem;
    }

    span:last-child {
      margin-left: 0.2rem;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    color: $black;
  }

  &__delete {
    position: absolute;
    top: 1.6rem;
    right: 4rem;
    width: 2rem;
    height: 2rem;
    color: $m-red;
    transition: 0.3s;

    @include hover {
      &:hover {
        transition: 0.3s;
        color: $m-blue;
      }
    }
  }

  &__delete-active {
    transform: translateX(110%);
    transition: 0.5s;
    opacity: 0;
  }
}