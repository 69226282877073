@import "src/style/mixins";
@import "src/style/variables";


.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  h1 {
    color:  #232323;
    text-align: center;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    @include small-mobile {
      font-size: 2.4rem;
    }
  }

  svg {
    width: 28.8rem;
    height: 7.7156rem;

    @include small-mobile {
      width: 14.9rem;
      height: 4rem;
    }
  }
}

