@import "src/style/mixins";
@import "src/style/variables";

.additional-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 88.1rem;
  gap: 1.9rem;
  margin-bottom: 2.4rem;
  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
    margin-bottom: 0.4rem;
  }
}

.title {
  color: #CBCBCB;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 2.4rem;

  @include medium-mobile {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }
}

.paymentBlock-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.9rem;

  @include small-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include medium-mobile {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.4rem;
  }
}

.paymentBlock {
  margin-bottom: 4.8rem;
  @include medium-mobile{
    margin-bottom: 4rem;
  }
}

.paymentBlock-title {
  color: $m-blackL;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 1.2rem;

  @include medium-mobile{
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
}

.input-payment {
  input {
    padding-left: 2.4rem !important;

    @include medium-mobile{
      padding-left: 1.4rem !important;
    }
  }
}

.firstBlock {
  position: relative;
}

.message {
  width: 100%;
  top: 100%;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: $m-blue;
}

