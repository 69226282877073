@import "src/style/mixins";
@import "src/style/variables";

.tourRoute {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 21;
}

.title {
  color: $black;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.9rem;
  text-align: left;
  margin-bottom: 1.8rem;
}

.tourRoute-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.tourRoute-body {
  position: absolute;
  max-width: 72rem;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 1.6rem;
}

.tourRoute-body-inner {
  position: relative;
  width: 100%;
  padding: 4rem 2.7rem 4rem 7rem;
  background: $white;

  @include small-mobile {
    padding-left: 3rem;
  }
}

.tourRoute-item {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  span {
    color: $black;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    text-align: left;
  }
}


.decor {
  display: flex;
  flex-direction: column;
  align-items: center;

  div:nth-child(1) {
    width: 1px;
    height: 1.6rem;
    background: $m-blue;
  }
  div:nth-child(2) {
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid $m-blue;
    border-radius: 50%;
  }
  div:nth-child(3) {
    width: 1px;
    height: 1.6rem;
    background: $m-blue;
  }
}

.burger-close {
  position: absolute;
  top: 2.7rem;
  right: 2.7rem;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
  transition: 0.3s;

  @include hover {
    &:hover {
      fill: $m-green;
    }
  }


}




